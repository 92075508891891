import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RequestOptions } from '@angular/http';
import { DateParseTokenFn } from 'ngx-bootstrap/chronos/types';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Master } from '../shared/common.service';

@Injectable({
  providedIn: 'root'
})

export class RiderService {
  pagedData: any;
  bytesResponse: any;
  masters: Master[] = [];
  public rideUri = `${environment.WebApiBaseUrl}Reports`;
  public baseUri = `${environment.WebApiBaseUrl}`;
  public RiderModuleUri = `${environment.WebApiBaseUrl}rider`;
  constructor(private http: HttpClient) { }

  saveRider(rider: FormData) {
    return this.http.post(`${this.baseUri}rider/PostAsync`, rider);

  }
  ClickToDownload(id: any) {
    return this.http.get(this.baseUri + `rider/DownloadwaiverPdfFile?id=${id}`, { responseType: 'blob' }).pipe(
      map((response: any) => {
        this.bytesResponse = response;
        return true;
      })
    );
  }
  //AssigntoDDl
  AssignToddl(masterNames: any): Observable<any> {
    return this.http.get(`${this.RiderModuleUri}/AssigntoDDl?masterNames=${masterNames}`).pipe(
      map((response: any) => {
        this.masters = response;

      })
    );
  }

  AssignToOpearatotddl(masterNames: any): Observable<any> {
    debugger;
    return this.http.get(`${this.RiderModuleUri}/AssigntoDDl?masterNames=${masterNames}`).pipe(
      map((response: any) => {
        this.masters = response;

      })
    );
  }
  getRiderNameDDl() {
    return this.http.get(`${this.rideUri}/GetRiderName`).pipe(
      tap(data => console.log('Machine DAta by Id', data)),
    );
  }
  GetRiderName_Superadmin() {
    return this.http.get(`${this.rideUri}/GetRiderName_Superadmin`).pipe(
      tap(data => console.log('Machine DAta by Id', data)),
    );
  }
  getRiderNameForFindAllDDl() {
    return this.http.get(`${this.rideUri}/GetRiderNameQuickFind`).pipe(
      tap(data => console.log('Machine DAta by Id', data)),
    );
  }

  getRiderList(nameSearch: any, userTypeData: any, isUnassignedRider: boolean, idSearch: any, phnSearch: any, active: number, sortColumn: string, sortDir, page: number, pageSize: number, userId: string, isExport = false) {
    debugger
    if (userTypeData == 'null' || userTypeData == 'undefined') {
      userTypeData=null
    }
    return this.http.get(this.baseUri + `rider/GetRiderList?nameSearch=${nameSearch}&userTypeData=${userTypeData}&isUnassignedRider=${isUnassignedRider}&idSearch=${idSearch}&phnSearch=${phnSearch}&active=${active}&SortColumn=${sortColumn}&SortDir=${sortDir}&PageNo=${page}&PageSize=${pageSize}&UserId=${userId}&isExport=${isExport}`)
      .pipe(
        map((response: any) => {
          this.pagedData = response;
          return true;
        })
      )
  }
  getRiderListXAmrin(appversion: string, frmversion: string, nameSearch: any, idSearch: any, phnSearch: any, active: number, sortColumn: string, sortDir, page: number, pageSize: number, userId: string) {
    return this.http.get(this.baseUri + `rider/GetRiderListForXamrain?appversion=${appversion}&frmversion=${frmversion}&nameSearch=${nameSearch}&idSearch=${idSearch}&phnSearch=${phnSearch}&active=${active}&SortColumn=${sortColumn}&SortDir=${sortDir}&PageNo=${page}&PageSize=${pageSize}&UserId=${userId}`)
      .pipe(
        map((response: any) => {
          this.pagedData = response;
          return true;
        })
      )
  }
  DeleteRider(Id: any) {
    return this.http.get(this.baseUri + `Rider/DeleteRiderById?Id=${Id}`)
  }
  DeleteRiders(selected: string) {
    return this.http.get(this.baseUri + `Rider/DeletedRiders?riderIds=${selected}`)
  }
  setRiderStatus(Id: any, IsActive: any) {
    return this.http.get(this.baseUri + `Rider/ChangedRiderStatusById?Id=${Id}&IsActive=${IsActive}`)
  }
  GetRiderById(Id: string) {
    return this.http.get<Rider>(`${this.RiderModuleUri}/GetRiderById?Id=${Id}`)
      .pipe(
        tap(data => console.log('Rider DAta by Id', data)),
      );
  }
}
export class Rider {
  id: string;
  firstName: string;
  lastName: string;
  phoneNo: string;
  email: string;
  gender: string;
  assignedTo: any;
  nowaivertype: string;
  collectWaiver: boolean;
  emailAfterCoreEvaluations: boolean;
  emailAfterRides: boolean;
  isActive: boolean;
  uploadProfilePic: string;
  profilePicLink: string;
  dob: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  reAssignedTo: any;
  pdf: any;
  isWaiverRequired: boolean;


  constructor() {
    this.id = '',
      this.firstName = '',
      this.lastName = '',
      this.phoneNo = '',
      this.email = '',
      this.gender = "",
      this.assignedTo = "",
      this.nowaivertype = "",
      this.collectWaiver = false,
      this.emailAfterCoreEvaluations = false,
      this.emailAfterRides = false,
      this.isActive = false,
      this.uploadProfilePic = '',
      this.profilePicLink = '',
      this.dob = "",
      this.address = "";
    this.state = null;
    this.zipCode = "";
    this.city = "";
    this.reAssignedTo = "";
    this.pdf = "";
    this.isWaiverRequired = false;
  }


}
